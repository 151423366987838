@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap');

*,
 p,
 a div {
   font-family: 'Poppins', sans-serif;
 }

 a {
   color: #ffffff;
   text-decoration: none;
 }

 a:hover {
   color: silver;
 }

 body {
   background: #F5F7F9;
 }

 a.nav-link.store-btn-sell {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 6px 34px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
   height: fit-content;
   padding-left: 20px !important;
   padding-right: 20px !important;
   width: fit-content;
   margin: auto;
 }

 .store-btn-account {
   background: linear-gradient(247deg, #adadad, #d5d3d3);
   color: white;
   padding: 6px 34px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #2c2c2c69;
   height: -moz-fit-content;
   height: fit-content;
   padding-left: 20px !important;
   padding-right: 20px !important;
   width: -moz-fit-content;
   width: fit-content;
   margin: auto;
 }

 @media (min-width: 992px) {
   .store-page-section .navbar-expand-lg .navbar-nav .nav-link {
     padding-right: 2.5rem;
     padding-left: 0.5rem;
   }
 }

 @media only screen and (max-width: 992px) {

   .store-nav .navbar-collapse .navbar-nav {
     margin: auto;
     text-align: center;
     background-color: rgb(4 4 4 / 24%);
     -webkit-backdrop-filter: blur(11px);
     backdrop-filter: blur(23px);
     border-radius: 20px;
     padding: 25px 0px;
   }

   .store-nav svg {
     width: 128px;
   }
 }

 .store-nav .navbar-nav {
   display: flex;
   justify-content: center !important;
   align-items: center !important;
   /* margin: auto; */
 }

 .navbar-nav {
   font-size: 14px;
 }

 .store-home-hero .store-hero {
   height: 500px;
 }

 .store-hero {
   height: 358px;
   /* background: #777777; */
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-size: cover;
   /* background-image: url('./img/bg.png'); */
   background-position: center;
 }

 .boutique-hero .store-hero {
   height: 358px;
   /* background: #777777; */
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-size: cover;
   /* background-image: url('./img/bg.png'); */
   background-position: center;
   padding-top: 364px;
 }

 .store-h3 {
   font-size: 30px;
   color: white;
   margin-bottom: 0;
 }

 .store-h1 {
   font-size: 65px;
   color: white;
   font-weight: 800;
   margin: 0;
   text-align: center;
 }

 @media only screen and (max-width: 992px) {

   .boutique-hero .store-hero {
     height: fit-content;
     /* background: #777777; */
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     background-size: cover;
     /* background-image: url('./img/bg.png'); */
     background-position: center;
     padding-top: 90px;
     padding-bottom: 61px;
   }

 }

 @media only screen and (max-width: 600px) {
   .store-h1 {
     font-size: 40px;
     line-height: 1;
     margin-top: 16px;
   }

   .store-h3 {
     font-size: 21px;
     color: white;
     margin-bottom: 0;
   }
 }

 .captcha-err {
   color: red;
   font-size: 14px;
   font-weight: 400;
 }

 .err_login {
   color: #ff1d1d;
   font-size: 13px;
 }

 p.store-h1 span {
   color: #f53433;
 }

 .store-nav>.nav-link {
   color: rgb(255 255 255) !important;
 }

 .store-nav .navbar-nav .nav-link {
   color: rgb(255 255 255) !important;
 }

 .navbar .nav-link.active {
   font-weight: 600;
 }

 /* Start Search Bar */

 .search-bar-section {
   background: white;
   height: 91px;
   border-radius: 45px;
   margin: -55px auto auto;
   background-color: rgb(255 255 255 / 61%);
   backdrop-filter: blur(11px);
 }

 .search-sections {
   display: flex;
   align-items: center;
   height: 100%;
   padding: 14px 18px;
   /* padding: 20px 45px; */
   justify-content: space-around;
   flex-wrap: wrap;
 }

 .search-sec input {
   margin-left: 20px;
   font-size: 20px;
 }

 .search-sec input[type="text"] {
   background: transparent;
   border: none;
 }

 .search-sec input[type="text"]:focus {
   outline: none;
 }

 .search-bar-section SELECT {
   background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
   background-position: calc(100% - 0.75rem) center !important;
   -moz-appearance: none !important;
   -webkit-appearance: none !important;
   appearance: none !important;
   padding-right: 2rem !important;
 }

 .search-btn-sec {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 6px 34px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
   height: -moz-fit-content;
   height: fit-content;
   padding-left: 20px !important;
   padding-right: 20px !important;
 }

 .search-cat-sec {
   position: relative;
 }

 .search-cat-sec select {
   border: none;
   background-color: transparent;
   font-size: 14px;
   margin-left: 18px;
 }

 .search-cat-sec:before {
   content: '';
   background: #e7e7e7;
   height: 90px;
   width: 4px;
   margin-top: -22px;
   position: absolute;
   display: flex;
   box-shadow: 0 0 8px #afadad8f;
 }

 .search-small-title {
   color: #E01C3B;
   font-weight: 600;
   font-size: 14px;
   margin-left: 18px;
 }

 .search-cat-sec select:focus-visible {
   outline: none;
   border: none;
   background-color: transparent;
 }

 /* Start Category */
 .store-cat-head {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
 }

 @media only screen and (max-width: 600px) {
   .store-cat-head {
     justify-content: center;
     flex-direction: column;
   }
 }

 .store-cat-head p svg {
   margin-right: 9px;
 }

 .store-cat-head p {
   font-size: 23px;
   font-weight: 700;
   color: #363C5A;
   display: flex;
   align-items: center;
 }

 .store-cat-btn {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 4px 34px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
 }

 .store-cat {
   background: white;
   margin-top: 140px;
   padding: 107px 0px;
 }

 .store-boutiques {
   margin-top: 140px;
   padding: 6px 0px;
 }


 /* Start Boutique */

 .boutique-card {
   background: white;
   width: -moz-fit-content;
   width: fit-content;
   height: 336px;
   width: 100%;
   margin-bottom: 92px;
   margin-left: auto;
   margin-right: auto;
   border-radius: 38px;
   overflow: hidden;
   box-shadow: 3px -1px 8px #adadad2b;
 }

 .img-b-bg {
   min-height: 229px;
   max-height: 229px;
   width: 100%;
   object-fit: cover;
 }

 .search-page .img-b-bg {
   /* min-height: 229px;
  max-height: 229px;
  width: 100%; */

   min-height: 229px;
   max-height: 229px;
   width: 100%;
   object-fit: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: auto;
 }

 .boutique-content {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 20px 37px;
 }

 .boutique-logo {
   border: 1px solid #B2B2B2;
   border-radius: 42px;
   height: 59px;
   width: 59px;
   display: flex;
   justify-content: center;
   align-items: center;
   object-fit: cover;
   overflow: hidden;
 }

 .boutique-logo img {
   height: 100%;
   width: 100%;
   object-fit: contain;
 }

 .boutique-title {
   font-size: 19px;
   font-weight: 700;
   margin-bottom: 0px;
   color: #363C5A;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   max-width: 21ch;
 }

 .boutique-map {
   font-size: 14px;
   color: #949494;
   margin-bottom: 0px;
 }




 /* Start Banner */

 .banner-content {
   margin: -108px auto auto;
   background: #ffffff;
   padding: 31px 75px;
   border-radius: 24px;
   display: flex;
   justify-content: space-between;
   width: 77%;
   align-items: center;
 }

 @media only screen and (max-width: 992px) {
   .banner-content {
     margin: -108px auto auto;
     background: #ffffff;
     padding: 31px 31px;
     border-radius: 24px;
     display: flex;
     justify-content: space-between;
     width: 100%;
     align-items: center;
     flex-direction: column;
   }
 }

 .banner-h1 {
   font-size: 24px;
   color: #737373 !important;
   font-weight: 700;
   margin-bottom: 0;
 }

 .banner-desc {
   font-size: 17px;
   color: #737373 !important;
   margin-bottom: 0;
 }

 .banner-btn {
   background: #737373;
   padding: 7px 31px;
   border-radius: 13px;
   font-size: 19px;
   color: white;
   box-shadow: 1px 1px 4px #00000038;
 }



 /* START FOOTER  */
 section.footer-store {
   background: linear-gradient(179deg, #e53c57, #f59996);
   /* height: 301px; */
   margin-top: 298px !important;
 }

 section.footer-store p {
   color: white;
 }

 .logo-footer-store {
   margin-right: 34px;
   border-right: 1px solid white;
   padding-right: 20px;
 }

 .text-footer-store {
   display: flex;
   justify-content: center;
   /* align-items: center; */
   flex-direction: column;
 }

 .text-footer-store p {
   color: white;
   font-size: 13px;
   line-height: 14px;
 }

 .copyright {
   font-size: 18px;
   font-weight: 800;
   color: white;
 }

 .contact-footer-store p {
   margin-bottom: 7px;
   font-size: 15px;
 }

 @media only screen and (max-width: 992px) {
   .footer-store {
     text-align: center;
   }
 }

 @media only screen and (max-width: 1200px) {
   .copyright-logo {
     align-items: center !important;
     justify-content: center;
     text-align: center;
     flex-direction: column;
     margin-bottom: 25px;
   }

   .logo-footer-store {
     border-right: none;
     padding-right: 0px;
     margin-right: 0px;
   }
 }

 .footer-store-icon svg {
   margin-right: 11px;
 }

 /* END FOOTER */



 /* Start Aide Page */
 .Aide-page {
   background: #F5F7F9;
 }

 .Aide-page .masthead {
   height: 100vh;
   min-height: 500px;
   /* background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080'); */
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
 }

 .Aide-page .aide-header-title {
   font-size: 56px;
   color: white;
   font-weight: 700;
 }

 .Aide-page .aide-header-sub {
   color: white;
   font-size: 24px;
   font-weight: 500;
   line-height: 30px;
 }

 .Aide-page .aide-header-btn {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 10px 28px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
   height: -moz-fit-content;
   height: fit-content;
   width: -moz-fit-content;
   width: fit-content;
   font-size: 21px;
   margin: auto;
   font-weight: 600;
 }

 .Aide-page .etap-title {
   color: #363C5A;
   font-size: 32px;
   font-weight: 700;
 }

 .Aide-page .etap-sub {
   color: #363C5A;
   font-size: 20px;
   font-weight: 500;
 }

 .Aide-page .etap-item {
   background: white;
   padding: 37px;
   border-radius: 15px;
   position: relative;
   padding-top: 103px;
 }

 .Aide-page .etap-item-title {
   font-size: 20px;
   color: #D82F57;
   font-weight: 800;
 }

 .Aide-page .etap-item-sub {
   color: #5C6272;
   font-weight: 900;
 }

 .Aide-page .etap-item img {
   position: absolute;
   left: 50%;
   transform: translate(-50%, -133%);
 }


 .Aide-page .avantage-item {
   background: white;
   padding: 37px;
   border-radius: 25px;
   box-shadow: 5px 18px 20px #5c5c5c0d;
   min-height: 319px;
 }

 .Aide-page .avantage-item-title {
   font-size: 24px;
   font-weight: 500;
   color: #CC1330;
 }

 .Aide-page .avantage-item-title svg {
   width: 100px;
 }

 .Aide-page .avantage-item p {
   color: #5c5a5a;
 }

 .Aide-page .bg-padding {
   background: white;
   padding-bottom: 200px;
   margin-top: 116px;
 }

 .Aide-page .accordion-item {
   padding: 16px;
   margin-bottom: 29px;
   border-radius: 24px !important;
   box-shadow: -1px 3px 10px #80808026;
 }

 .Aide-page .accordion-button:not(.collapsed) {
   color: #CC1330;
   font-size: 24px;
 }

 .Aide-page .accordion-button {
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   padding: 1rem 1.25rem;
   font-size: 19px;
   color: #CC1330;
   font-weight: 600;
   text-align: left;
   background-color: #fff;
   border: 0;
   border-radius: 0;
   overflow-anchor: none;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
 }

 .Aide-page .accordion-button:not(.collapsed) {
   background-color: transparent;
   box-shadow: none;
 }

 .Aide-page .accordion-body {
   color: #585656;
 }



 /* Start Single Boutique */

 .boutique-single-logo {
   background: white;
   width: 260px;
   height: 260px;
   border-radius: 123px;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: -174px;
   /* padding: 11px; */
   border: 6px solid #F5F7F9;
   position: absolute;
   object-fit: cover;
   z-index: +999;
 }

 .boutique-single-logo img {
   object-fit: contain;
   width: 100%;
   height: 100%;
 }

 .boutique-single-info {
   margin-top: -183px;
   margin-left: 284px;
   color: white;
   position: absolute;

 }

 .boutique-single-top-area {
   display: flex;
   position: relative;
 }

 .boutique-single-title {
   font-size: 27px;
   font-weight: 700;
   text-transform: capitalize;
 }

 .boutique-single-nav {
   width: 100%;
   background: white;
   position: absolute;
 }

 @media only screen and (max-width: 992px) {


   /* .boutique-single-logo {
     margin-top: -241px;
   }

   .boutique-single-info {
     margin-top: -215px;
     margin-left: 284px;
     color: white;
     position: absolute;
   } */
 }

 .b-nav-section {
   margin-left: 287px;
   display: flex;
   height: 54px;
   justify-content: flex-start;
   /* align-items: center; */
 }

 .b-single-nav {
   margin-right: 41px;
   padding-bottom: 28px;
   padding-top: 14px;
   color: #363636;
   cursor: pointer;
 }

 .b-single-nav-active a {
   color: #F67474;
   text-decoration: none;
 }

 .b-single-nav-active {
   margin-right: 41px;
   border-bottom: 3px solid #F67474;
   padding-bottom: 28px;
   padding-top: 14px;
   color: #F67474;
 }

 .b-single-nav a {
   color: #363636;
   text-decoration: none;
 }

 .boutique-single-page-home .search {
   position: relative;
   width: 94%;
   /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
 }

 .boutique-single-page-home .search input {
   height: 48px;
   text-indent: 25px;
   border: none;
   border-radius: 44px;
 }

 .boutique-single-page-home .search input:focus {
   box-shadow: none;
   border: 2px solid red;
 }

 .boutique-single-page-home .search .fa-search {
   position: absolute;
   top: 20px;
   left: 16px;
 }

 .boutique-single-page-home .search button {
   position: absolute;
   top: 5px;
   right: 5px;
   height: 38px;
   width: 76px;
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   border: none;
   color: white;
   border-radius: 38px;
 }

 .contact-boutique-single input {
   border-radius: 19px;
   padding: 8px 11px;
 }

 .contact-boutique-single textarea {
   min-height: 190px;
   border-radius: 20px;
 }

 .contact-boutique-single iframe {
   border-radius: 24px;
 }

 .btn-boutique-sent {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 7px 53px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
   height: -moz-fit-content;
   height: fit-content;
   width: -moz-fit-content;
   width: fit-content;
 }

 .info-boutique-list {
   background: white;
   padding: 34px 48px;
   border-radius: 29px;
   box-shadow: 0px 4px 8px #0000000f;
 }

 .boutique-card-item {
   background: white;
   width: -moz-fit-content;
   width: 100%;
   height: 295px;
   margin-bottom: 92px;
   margin-left: auto;
   margin-right: auto;
   border-radius: 38px;
   overflow: hidden;
   box-shadow: 3px -1px 8px #adadad2b;
   position: relative;
   display: flex;
   flex-direction: column;
 }

 .boutique-item-content {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 27px 22px;
   background: #fdfdfd;
 }

 .boutique-item-taksit {
   position: absolute;
   right: 18px;
   top: 146px;
   background: linear-gradient(210deg, #E01C3B, #FB807C);
   padding: 4px 18px;
   border-radius: 22px;
   color: white;
   font-weight: 800;
 }

 .boutique-single-price {
   font-size: 19px;
   font-weight: 500;
   color: #8f8e8e;
 }

 .boutique-card-item img {
   max-height: 156px;
   margin: auto;
   text-align: center;
   background: #FFFFFF;
   display: flex;
   min-height: 165px;
   /* max-height: 167px; */
   width: 100%;
   object-fit: cover;
 }

 .boutique-single-item-price {
   font-size: 16px;
   font-weight: 500;
   color: #8f8e8e;
   margin-top: 12px;
 }

 .boutique-item-title {
   font-size: 15px;
   font-weight: 700;
   margin-bottom: 0px;
   color: #363C5A;
   line-height: 16px;
 }

 @media only screen and (max-width: 600px) {

   .boutique-single-logo {
     background: white;
     width: 180px;
     height: 180px;
     border-radius: 123px;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: -259px;
     /* padding: 11px; */
     border: 6px solid #F5F7F9;
     position: absolute;
     object-fit: cover;
     z-index: +999;
     margin-left: 14px !important;
   }

   .boutique-single-info {
     margin-top: -77px;
     margin-left: 29px;
     color: #ffffff;
     position: absolute;
   }



   .store-home-hero .store-hero {
     height: 366px;
   }

   .store-cat {
     background: white;
     margin-top: 61px;
     padding: 67px 0px;
   }

   .store-boutiques {
     margin-top: 67px;
     padding: 6px 0px;
   }

   .Aide-page .aide-header-title {
     font-size: 44px;
     color: white;
     font-weight: 700;
   }

   .Aide-page .aide-header-sub {
     color: white;
     font-size: 19px;
     font-weight: 500;
     line-height: 30px;
   }

 }











 /* Start Create Account */

 .create-account .masthead {
   height: 30vh;
   min-height: 407px;
   padding-top: 100px;
 }

 .form-store {
   background: white;
   padding: 61px;
   border-radius: 24px;
   box-shadow: 0px 6px 20px #cbcbcb;
 }

 @media only screen and (max-width: 992px) {
  .form-store {
    background: white;
    padding: 15px;
    border-radius: 24px;
    box-shadow: 0px 6px 20px #cbcbcb;
}
 }

 .store-next-btn {
   background: linear-gradient(247deg, #E01C3B, #FB807C);
   color: white;
   padding: 8px 34px;
   border-radius: 23px;
   box-shadow: 0px 8px 14px #e01c3b69;
   height: -moz-fit-content;
   height: fit-content;
   width: fit-content;
   cursor: pointer;
 }



 /* Start Account Page */

 .account {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding-top: 47px;
   padding-bottom: 110px;
   background: linear-gradient(45deg, #f3f3f3, white);
 }

 .account .nav-pills .nav-link.active,
 .nav-pills .show>.nav-link {
   background: linear-gradient(45deg, #fa7d98, #febba6);

 }

 .account .nav-link {
   color: #545454;
 }

 .account button.login-btn {
   border: none;
   background: linear-gradient(45deg, #00a9ed, #01398e);
   padding: 10px 20px;
   width: 100%;
   border-radius: 20px;
   color: white;
 }

 .account button.login-btn-fb {
   border: none;
   background: linear-gradient(45deg, #005efa, #00a4f8);
   padding: 10px 20px;
   width: 100%;
   border-radius: 20px;
   color: white;
 }

 .account form {
   max-width: 700px;
 }

 .account .tab-pane {
   background: white;
   box-shadow: 0px -2px 20px #d5d5d5;
   border-radius: 19px;
   padding: 28px 20px 56px 20px;
 }

 .account .back-to-dd {
   color: #585858;
 }

 /* End Account Page */






 /* Start Dashboard */

 .dashboard .menu {
   min-height: 100vh;
   height: 100vh;
   background: linear-gradient(322deg, #01398e, #00a9ed);
   /* background: linear-gradient(193deg, #E3427C, #D43561); */
   /* background: linear-gradient(193deg, #b7446e, #e57777); */
   width: 328px;
   position: fixed;
   top: 0;
   left: 0;
   transition: .3s all;
   overflow: auto;
   padding-bottom: 80px;
   z-index: +7;
 }

 .btn-dashboard-close {
   cursor: pointer;

 }

 .store-search-section {
   width: 92%;
   padding: 31px 5px;
   background: #fbfbfb;
   margin: auto;
   margin-top: 40px;
   border-radius: 17px;
   box-shadow: 2px -1px 20px 4px #26262612;
   display: flex;
   /* justify-content: safe center; */
   align-items: safe center;

   overflow-x: scroll;
 }

 .store-search-section div:first-child {
   /* margin-left: auto; */
 }

 .store-search-section div:last-child {
   /* margin-right: auto; */
 }

 .store-search-section input {
   border: 3px solid #e3e3e3;
   border-radius: 22px;
   width: 237px;
 }

 .store-search-section .form-select {
   border: 3px solid #e3e3e3;
   border-radius: 22px;
   width: 157px;
 }

 .store-search-section select {
   font-size: 13px;
   /* color: darkgrey; */
   font-weight: 600;

 }

 .store-search-section input::placeholder {
   font-size: 13px;
   color: darkgrey;
   font-weight: 600;
 }

 .store-search-section .form-control:focus {
   color: #212529;
   background-color: #fff;
   border-color: #ffffff;
   outline: 0;
   box-shadow: 0 0 8px 0rem rgb(47 190 219);
 }

 .search-btn-dashboard {
   background: linear-gradient(45deg, #f783ee, #797cfd) !important;
   width: fit-content;
   padding: 5px 23px;
   border-radius: 20px;
   cursor: pointer;
 }

 .dashboard .menu-head {
   display: flex;
   justify-content: space-around;
   align-items: center;
 }

 .dashboard .menu-separation {
   border: 1px solid #ffffffb3;
   /* margin-top: 63px; */
   width: 69%;
   margin-left: auto;
   margin-right: auto;
 }

 .dashboard .menu-link {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 33px;
 }

 .dashboard .back-store {
   color: white;
   text-align: center;
   margin-top: 34px;
   font-size: 15px;
   font-weight: 600;
   font-style: italic;
 }

 /* width */
 ::-webkit-scrollbar {
   width: 10px;
 }

 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #7288db;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #555;
 }

 .menu-active {
   background: linear-gradient(322deg, #b9c4d5, #3885f9);
   border-radius: 27px;
   color: #d83969;
   /* justify-content: space-around; */
 }

 .menu-active {

   align-items: center;
   font-weight: 800 !important;
   color: #ffffff !important;
   text-decoration: none;
 }

 .menu-active svg {
   margin-right: 20px;
   width: 24px
 }

 .menu-active svg path,
 .menu-active svg rect {
   /* fill: #d83969 !important; */
   fill: #ffffff !important;
 }

 .menu-url svg {
   margin-right: 28px;
   min-width: 24px
 }

 .menu-url svg path {
   fill: white;
 }

 .menu-url {
   color: white;
   font-size: 15px;
   width: 247px;
   display: flex;
   align-items: center;
   font-weight: 400;
   padding: 5px 17px;
   /* justify-content: space-around; */
   margin-top: 24px;
   line-height: 1;
 }

 .menu-url a {
   display: flex;
 }

 .menu-logout {
   margin-top: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
 }

 .menu-logout p {
   color: #ffffff;
   background: linear-gradient(45deg, #ff978c, #ff6db6) !important;
   padding: 7px 23px;
   border-radius: 18px;
   font-weight: 700;
   font-size: 14px;
 }

 .menu-logout {
   margin-top: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   /* background: #AE254D; */
   background: #7185d347;
   padding-top: 82px;
   padding-bottom: 9px;
   border-radius: 20px;
   width: 80%;
   margin-left: auto;
   margin-right: auto;
   cursor: pointer;
 }

 .page-client {
   transition: .3s all;
   background: #f3f4f5;
   min-height: 100vh;
   padding-bottom: 100px;
 }

 .menu-mobile-section svg {
   display: none;
 }

 .page-title {
   font-size: 24px;
   font-weight: 900;
   color: #707070;
   margin-left: 64px;
   border-bottom: 1px solid #d1d1d1;
   padding-bottom: 20px;
 }

 .page-content {
   background: white;
   padding: 49px;
   border-radius: 18px;
   width: 93%;
   margin: auto;
   margin-top: 80px;
   box-shadow: 3px 1px 20px #2626261f;
 }

 @media only screen and (max-width: 992px) {

   .page-client {
     margin-left: 0 !important;
     margin-top: 85px;

   }

   .menu-mobile-section {
     height: 58px;
     background: linear-gradient(328deg, #384884, #4F5F9C);
     margin-bottom: 30px;
     display: flex;
     justify-content: flex-end;
     align-items: center;
     padding: 2px 31px;
     position: fixed;
     width: 100%;
     top: 0;
     z-index: +1;
   }

   .menu-mobile-section svg {
     display: flex;
   }

   .menu {
     left: -328px;
   }

   .page-content {
     background: white;
     padding: 39px 11px;
     border-radius: 18px;
     width: 93%;
     margin: auto;
     margin-top: 80px;
     box-shadow: 3px 1px 20px #2626261f;
   }

   .page-title {
     border-bottom: 1px solid #d1d1d1;
     color: #707070;
     font-size: 24px;
     font-weight: 900;
     margin: 0px 10px;
     padding-bottom: 20px;
   }
 }





 .page-title span {
   font-size: 14px;
   font-weight: 500;
   margin-left: 20px;
 }



 .box-info {
   background: linear-gradient(45deg, #2ac6d5, #4b86fe);
   height: 200px;
   padding: 47px;
   border-radius: 20px;
   color: white;
   padding-top: 34px
 }

 .box-info svg {
   margin-bottom: 16px;
   height: 37px;
   width: 36px;
 }

 .box-info p:nth-child(2) {
   font-size: 18px;
 }

 .box-info p:nth-child(3) {
   font-size: 38px;
   font-weight: 700;
 }

 @media screen and (max-width: 1200px) and (min-width: 991px) {
   .box-info {
     background: linear-gradient(45deg, #2ac6d5, #4b86fe);
     height: 200px;
     padding: 24px 19px;
     border-radius: 20px;
     color: white;
     padding-top: 34px;
   }

   .box-info p:nth-child(2) {
     font-size: 16px;
   }
 }

 .bg-g-second {
   background: linear-gradient(45deg, #f783ee, #797cfd) !important;
 }

 .bg-g-third {
   background: linear-gradient(45deg, #ff978c, #ff6db6) !important;
 }

 .list-group-item.active {
   z-index: 2;
   background: linear-gradient(328deg, #384884, #4F5F9C);
   border: none;
   color: #fff;
   font-weight: 500;
   padding: 9px 25px;
   text-align: center;
   font-size: 21px;
 }

 .list-group-item {
   position: relative;
   display: block;
   padding: .8rem 1rem;
   color: #212529;
   text-decoration: none;
   background-color: #fff;
   border: 1px solid rgba(0, 0, 0, .125);
 }

 .list-store-admin {
   background: white;
   border-radius: 18px;
   width: 93%;
   margin: auto;
   margin-top: 80px;
   box-shadow: 2px -1px 20px 4px #26262612;
   border-radius: 14px;
   overflow: hidden;
 }

 .list-group-item.active {
   z-index: 2;
   background: linear-gradient(45deg, #2ac6d5, #4b86fe);
   border: none;
   color: #fff;
 }

 .list-group b {
   font-weight: bolder;
   font-size: 14px;
   color: #6a6a6a;
 }

 .vendeursadminlist {
   display: flex !important;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
 }

 .delete-btn {
   background: linear-gradient(45deg, #ff978c, #ff6db6) !important;
   color: white;
   font-weight: 600;
   padding: 4px 10px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
   cursor: pointer;
   width: fit-content;
 }

 .validate-btn {
   color: white;
   font-weight: 600;
   padding: 2px 12px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
   background: #56b5bf;
   cursor: pointer;
 }

 .update-btn {
   background: #596db9;
   width: -moz-fit-content;
   width: fit-content;
   padding: 2px 12px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
 }

 a.vendeur-dossier-voir {
   background: linear-gradient(45deg, #f783ee, #797cfd) !important;
   padding: 2px 14px;
   border-radius: 20px;
   color: white !important;
 }

 .new-admin-btn {
   background: linear-gradient(45deg, #2ac6d5, #4b86fe) !important;
   width: fit-content;
   margin: auto;
   margin-top: 35px;
   padding: 8px 19px;
   border-radius: 21px;
   color: white;
   font-weight: 600;
   cursor: pointer;
 }

 /* End Dashboard */










 /* Table */
 .dashboard-table {
   background: white;
   border-radius: 18px;
   width: 93%;
   margin: auto;
   margin-top: 35px;
   box-shadow: 2px -1px 20px 4px #26262612;
   border-radius: 20px;
   overflow: hidden;
 }

 .detail-btn {
   background: linear-gradient(45deg, #2ac6d5, #4b86fe);
   width: -moz-fit-content;
   width: fit-content;
   padding: 2px 12px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
 }

 .account-store-btn {
   background: linear-gradient(45deg, #586364, #a4a4a4);
   width: -moz-fit-content;
   width: fit-content;
   padding: 5px 5px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
 }

 .voir-btn {
   background: linear-gradient(45deg, #2ac6d5, #4b86fe);
   width: -moz-fit-content;
   width: fit-content;
   padding: 5px 6px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
 }

 .update-btn {
   /* background: linear-gradient(45deg, #2ba37c, #72dba8); */
   background: #596db9;

   width: -moz-fit-content;
   width: fit-content;
   padding: 4px 7px;
   border-radius: 6px;
   font-size: 13px;
   margin: auto;
 }

 .update-btn-payment {
  background: linear-gradient(45deg, #2ba37c, #72dba8);
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 7px;
  border-radius: 6px;
  font-size: 13px;
  margin: auto;
}

 .dashboard-table img {
   max-width: 54px;
   max-height: 84px;
   overflow: hidden;
 }

 .dashboard-table table {
   /* border: 1px solid #ccc; */
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   width: 100%;
   table-layout: fixed;
   font-size: 13px;
   word-break: break-word;
 }

 .dashboard-table table caption {
   font-size: 1.5em;
   margin: .5em 0 .75em;
 }

 .dashboard-table table thead tr:first-child {
   background: #fbfbfb;
   height: 59px;
   color: #343434;
 }

 .dashboard-table table tr {
   background-color: #ffffff;
   border-bottom: 1px solid #dddddd;
   padding: .35em;
 }

 .dashboard-table table th,
 .dashboard-table table td {
   padding: 26px 3px;
   text-align: center;
   /* white-space: nowrap; */
   /* overflow: hidden; */
   /* text-overflow: ellipsis; */
   /* max-width: 75ch; */
 }

 .dashboard-table table th {
   font-size: 14px;
   font-weight: 600;
 }

 @media screen and (max-width: 800px) {
   .dashboard-table table {
     border: 0;
   }

   .dashboard-table table caption {
     font-size: 1.3em;
   }

   .dashboard-table table thead {
     border: none;
     clip: rect(0 0 0 0);
     height: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
     width: 1px;
   }

   .dashboard-table table tr {
     border-bottom: 39px solid #f3f4f5;
     display: block;
     margin-bottom: 14px;
   }

   .dashboard-table table td {
     border-bottom: 1px solid #ddd;
     display: block;
     font-size: 12px;
     text-align: right;
   }

   .dashboard-table table td::before {
     /*
    * aria-label has no advantage, it won't be read inside a.dashboard-table table
    content: attr(aria-label);
    */
     content: attr(data-label);
     float: left;
     font-weight: bold;
     text-transform: uppercase;
   }

   .dashboard-table table td:last-child {
     border-bottom: 0;
   }
 }








 /* Start Search Page */
 .search-hero {
   height: 245px;
   /* background: #777777; */
   display: flex;
   justify-content: flex-end;
   align-items: center;
   flex-direction: column;
   background-size: cover;
   /* background-image: url('./img/bg.png'); */
 }

 .search-hero .store-h1 {
   font-size: 65px;
   color: white;
   font-weight: 800;
   margin: 0;
   text-align: center;
   margin-bottom: 0px !important;
   padding-bottom: 22px !important;
   line-height: 0;
 }

 @media only screen and (max-width: 600px) {
   .search-hero .store-h1 {
     font-size: 40px;
     line-height: 1;
     margin-top: 16px;
   }
 }

 .pagination {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
 }

 .pagination a {
   color: black;
   float: left;
   padding: 8px 16px;
   text-decoration: none;
   transition: background-color .3s;
   border: 1px solid #ddd;
   margin: 0 4px;
   border-radius: 20px;
 }

 @media(max-width : 800px) {
   .pagination a {
     color: black;
     float: left;
     padding: 2px 9px;
     text-decoration: none;
     transition: background-color .3s;
     border: 1px solid #ddd;
     margin: 0 4px;
     border-radius: 20px;
     font-size: 15px;
   }
 }

 .pagination a.active {
   background-color: #f84771;
   color: white;
   border: none;
   border-radius: 20px;
 }

 .pagination a:hover:not(.active) {
   background-color: #ddd;
 }

 .search-page .boutique-card {
   background: white;
   width: -moz-fit-content;
   width: fit-content;
   height: 346px;
   width: 100%;
   margin-bottom: 92px;
   margin-left: auto;
   margin-right: auto;
   border-radius: 38px;
   overflow: hidden;
   box-shadow: 3px -1px 8px #adadad2b;
 }

 .search-page .boutique-content {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 5px 26px;
   height: 68px;
 }

 .search-page .boutique-logo {
   border: 1px solid #B2B2B2;
   border-radius: 42px;
   height: 41px;
   width: 41px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   object-fit: cover;
 }

 .search-page .boutique-title {
   font-size: 16px;
   font-weight: 700;
   margin-bottom: 0px;
   color: #363C5A;
 }

 .search-page .boutique-map {
   font-size: 13px;
   color: #949494;
   margin-bottom: 0px;
 }

 .boutique-content-store {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 5px 26px;
   height: 49px;
   background: #f5f7f9;
 }

 .boutique-title-store {
   font-size: 16px;
   font-weight: 700;
   margin-bottom: 0px;
   color: #b9b9b9;
 }

 .boutique-logo-store {
   border: 1px solid #B2B2B2;
   border-radius: 42px;
   height: 48px;
   width: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   object-fit: cover;
 }

 .boutique-logo-store img {
   height: 100%;
   object-fit: contain;
   width: 100%;
   background: white;
 }

 .store-search-empty {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 80px;
   font-size: 21px;
   font-weight: 400;
 }

 @media only screen and (max-width: 992px) {

   .search-bar-section {
     background: white;
     height: 169px;
     border-radius: 45px;
     margin: -55px auto auto;
     background-color: rgb(255 255 255 / 61%);
     -webkit-backdrop-filter: blur(11px);
     backdrop-filter: blur(11px);
   }

   .search-sections {
     display: flex;
     align-items: center;
     height: 100%;
     padding: 16px 33px;
     justify-content: space-around;
     flex-wrap: wrap;
   }

   .search-sec input {
     margin-left: 9px;
     font-size: 17px;
   }

   .search-sec svg {
     width: 27px;
   }

   .search-cat-sec:before {
     display: none;
     content: '';
     background: #e7e7e7;
     height: 90px;
     width: 4px;
     margin-top: -22px;
     position: absolute;
     box-shadow: 0 0 8px #afadad8f;
   }


 }

 @media (max-width : 500px) {


   .search-hero {
     height: 160px;
     /* background: #777777; */
     display: flex;
     justify-content: flex-end;
     align-items: center;
     flex-direction: column;
     background-size: cover;
     /* background-image: url('./img/bg.png'); */
   }

   .search-bar-section {
     background: white;
     height: 154px;
     border-radius: 45px;
     margin: -55px auto auto;
     background-color: rgb(255 255 255 / 61%);
     -webkit-backdrop-filter: blur(11px);
     backdrop-filter: blur(11px);
   }

   .search-sections {
     display: flex;
     align-items: center;
     height: 100%;
     padding: 11px 33px;
     justify-content: flex-start;
     flex-wrap: wrap;
   }

   .search-btn-sec {
     background: linear-gradient(247deg, #E01C3B, #FB807C);
     color: white;
     padding: 6px 34px;
     border-radius: 23px;
     box-shadow: 0px 8px 14px #e01c3b69;
     height: -moz-fit-content;
     height: fit-content;
     padding-left: 20px !important;
     padding-right: 20px !important;
     width: 100%;
     text-align: center;
     /* margin-top: 9px; */
   }

   .search-sec input {
     margin-left: 9px;
     font-size: 14px;
   }

   .search-sec svg {
     width: 19px;
   }

   .search-bar-store-btn {
     width: 100%;
   }
 }

 /* End Search Page */



 .img-up {
   cursor: pointer;
   position: relative;
   width: fit-content;
   margin: auto;
 }

 .img-perview {
   position: absolute;
   top: 3px;
   left: 4px;
   width: 192px;
   height: 193px;
   overflow: hidden;
   border-radius: 43px;
   object-fit: cover;
 }

 .img-perview img {
   height: 100%;
   object-fit: cover;
   width: 100%;
   object-position: center;

 }

 .up-close {
   position: absolute;
   top: 0;
   right: 0;
   z-index: +999;
   font-size: 20px;
   color: #ca5972;
   background: white;
   width: 32px;
   height: 31px;
   border-radius: 20px;
   text-align: center;
   border: 1px solid #cb5a72;
 }

 .err-cart-sum {
   text-align: center;
   font-size: 15px;
   color: red;
 }



 .switch {
   position: relative;
   display: inline-block;
   width: 60px;
   height: 34px;
 }

 .switch input {
   opacity: 0;
   width: 0;
   height: 0;
 }

 .slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: .4s;
   transition: .4s;
 }

 .slider:before {
   position: absolute;
   content: "";
   height: 26px;
   width: 26px;
   left: 4px;
   bottom: 4px;
   background-color: white;
   -webkit-transition: .4s;
   transition: .4s;
 }

 input:checked+.slider {
   background-color: #c74771;
 }

 input:focus+.slider {
   box-shadow: 0 0 1px #c74771;
 }

 input:checked+.slider:before {
   -webkit-transform: translateX(26px);
   -ms-transform: translateX(26px);
   transform: translateX(26px);
 }

 /* Rounded sliders */
 .slider.round {
   border-radius: 34px;
 }

 .slider.round:before {
   border-radius: 50%;
 }








 .loader {
   width: 100%;
   height: 15px;
   text-align: center;
 }

 .dot {
   position: relative;
   width: 15px;
   height: 15px;
   margin: 0 2px;
   display: inline-block;
 }

 .dot:first-child:before {
   animation-delay: 0ms;
 }

 .dot:first-child:after {
   animation-delay: 0ms;
 }

 .dot:last-child:before {
   animation-delay: 200ms;
 }

 .dot:last-child:after {
   animation-delay: 200ms;
 }

 .dot:before {
   content: "";
   position: absolute;
   left: 0;
   width: 15px;
   height: 15px;
   background-color: blue;
   animation-name: dotHover;
   animation-duration: 900ms;
   animation-timing-function: cubic-bezier(.82, 0, .26, 1);
   animation-iteration-count: infinite;
   animation-delay: 100ms;
   background: white;
   border-radius: 100%;
 }

 .dot:after {
   content: "";
   position: absolute;
   z-index: -1;
   background: black;
   box-shadow: 0px 0px 1px black;
   opacity: .20;
   width: 100%;
   height: 3px;
   left: 0;
   bottom: -2px;
   border-radius: 100%;
   animation-name: dotShadow;
   animation-duration: 900ms;
   animation-timing-function: cubic-bezier(.82, 0, .26, 1);
   animation-iteration-count: infinite;
   animation-delay: 100ms;
 }

 @keyframes dotShadow {
   0% {
     transform: scaleX(1);
   }

   50% {
     opacity: 0;
     transform: scaleX(.6);
   }

   100% {
     transform: scaleX(1);
   }
 }

 @keyframes dotHover {
   0% {
     top: 0px;
   }

   50% {
     top: -50px;
     transform: scale(1.1);
   }

   100% {
     top: 0;
   }
 }

 .screen {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #4e4949ad;
   display: flex;
   align-items: center;
   z-index: +99999999999999999999;
 }


 .boutique-single-cat-items {
   border-radius: 14px;
   cursor: pointer;
   padding: 10px 16px;
   background: white;
   margin-bottom: 7px;
 }

 .boutique-single-cat-items-active {
   background: linear-gradient(45deg, #b3b2b2, #c7c5c5);
   color: white;
   font-weight: 700;
 }


 .store-page-section .navbar-light .navbar-toggler {
   color: rgb(255 255 255);
   border-color: rgb(255 255 255);
   background: white;
 }


 .offcanvas {
   z-index: +99999999991;
 }

 .mob-menu li.nav-item {
   border-bottom: 1px solid #dbdbdb;
   width: 100%;
   padding: 0px 0px;
 }

 .mob-menu .cat-taksit-menu {
   background: #969293;
   padding: 6px 18px;
   color: white;
   border-radius: 8px;
   width: fit-content;
   margin: auto;
 }



 /* Start Product Page */
 .product-page {
   background: #f5f6f9;
   padding-top: 10px;
   /* margin-top: 127px; */

 }

 .title-section:after {
   border-bottom: 2px solid #dbdbdb;
   content: "";
   display: inline;
   height: 20px;
   margin-left: 1em;
   position: absolute;
   width: 100%;
 }

 .title-section {
   color: #605e5e;
   font-size: 20px;
   font-style: italic;
   font-weight: 800;
   height: auto;
   margin-bottom: 22px;
   overflow: hidden;
   position: relative;
   text-align: left;
   width: 100%;
 }

 .product-similary {
   background: #f5f6f9;
 }

 .card-product {
   background: #fff;
   border-radius: 7px;
   display: flex;
   flex-direction: column;
   height: 314px;
   justify-content: space-between;
   margin: auto;
   overflow: hidden;
   width: 215px;
   box-shadow: 3px 0 16px #0000001a;
   position: relative;

 }

 .first-section-product {
   padding: 20px 18px 0 12px;
 }

 .product-page .img-product {
   max-height: 140px;
   min-height: 140px;
   overflow: hidden;
 }

 .product-page .img-product img {
   border-radius: 6px;
   height: 100%;
   min-height: 166px;
   object-fit: cover;
   width: 100%;
 }

 .title-product {
   color: #42464a;
   font-size: 14px;
   font-weight: 600;
   margin-top: 13px;
 }

 .mark-product {
   background: #000;
   border: 1px solid #c7c7c7;
   color: #fff;
   font-size: 11px;
   padding: 1px 4px;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
 }

 .product-page-price {
   align-items: flex-start;
   background: none;
   padding: 14px 19px;
 }

 .price-product {
   align-items: center;
   background: linear-gradient(45deg, #e03375, #ed5859);
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 13px 10px 4px;
   position: relative;
   width: 100%;
 }

 .product-page-price .tag-taksit {
   background: #f84771;
   border-radius: 21px;
   color: #fff;
   font-size: 17px;
   font-weight: 700;
   line-height: 20px;
   margin-bottom: 4px;
   padding: 0 19px;
 }

 .product-page-price .price {
   color: #5d5e62;
   font-size: 17px;
   font-weight: 700;
 }

 .price span {
   font-size: 15px;
   font-weight: 400;
 }

 section.top-content {
   background: white;
 }

 .swiper-button-next:after,
 .swiper-button-prev:after {
   background: #fff;
   border-radius: 20px !important;
   box-shadow: -2px 1px 4px #00000066;
   color: #6a6a6a;
   font-size: 21px !important;
   font-weight: 900;
   padding: 8px 13px !important;
 }

 .product-titel {
   font-size: 24px;
   color: #293847;
   border-top: 1px solid #D6D6D6;
   padding-top: 20px;
   padding-bottom: 20px;
   border-bottom: 1px solid #D6D6D6;
   font-weight: 600;
 }

 .product-price {
   align-items: center;
   background: linear-gradient(45deg, #201f1f, #3e3e3e);
   /* border: 1px solid #feb5a5; */
   border-radius: 2px;
   color: #fff8f8;
   display: flex;
   font-size: 21px;
   font-weight: 700;
   justify-content: center;
   padding: 2px 18px;
   width: -moz-fit-content;
   width: -webkit-fit-content;
   width: fit-content;
   transform: skew(172deg, 357deg);
 }

 .product-price span {
   font-size: 14px;
   font-weight: 500;
 }

 .product-mark,
 .product-desc {
   font-weight: normal;
 }

 .product-mark span,
 .product-desc span {
   color: #6E6E6E;
   font-size: 14px;
   font-weight: bold;
 }

 .product-purchase {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
 }

 .product-q-p {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
 }

 .product-cat {
   border-bottom: .5px solid #d5d5d563;
   color: #ec3560;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 33px;
   padding-bottom: 16px;
 }

 .product-cat a {
   color: #545454;
 }

 @media only screen and (max-width: 992px) {
   .product-purchase {
     flex-direction: column;
     align-items: flex-start;
   }

   .cart .sell-btn {
     margin-left: auto !important;
     margin-right: auto !important;

   }

   .sell-btn {
     margin-left: 20px !important;
   }

   .product-purchase .product-quantity,
   .product-purchase .sell-btn,
   .product-purchase .call-btn {
     margin-top: 20px;
   }

   .product-attr {
     margin-top: 20px;
   }
 }

 .image-gallery-thumbnail {
   width: 66px !important;
 }

 .image-gallery-thumbnails-wrapper.left,
 .image-gallery-thumbnails-wrapper.right {
   width: 64px !important;
 }

 .image-gallery-thumbnail .image-gallery-thumbnail-inner {
   display: block;
   position: relative;
   max-height: 54px;
   overflow: hidden;
   min-height: 56px;
 }

 @media only screen and (max-width: 1400px) {

   .image-gallery-content img.image-gallery-thumbnail-image {
     max-height: 52px !important;
     min-height: 52px !important;
     object-fit: cover;
   }
 }

 @media only screen and (max-width: 1400px) {

   .call-btn {
     background: linear-gradient(231deg, #ffc0ba, #ef5f80);
     width: -moz-fit-content;
     width: fit-content;
     color: white;
     padding: 7px 23px;
     border-radius: 25px;
     font-size: 20px;
     margin-left: 0px !important;
     margin-top: 22px;
     box-shadow: 4px 5px 18px #00000038;
   }
 }


 @media only screen and (max-width: 1200px) {
   .product-attr {
     display: flex;
     flex-wrap: wrap;
     margin-top: 20px;
   }
 }

 .product-quantity {
   display: flex;
   font-size: 14px;
   align-items: center;
 }

 .cart .quantity-btn {
   margin-left: 0px;
   font-size: 17px;
 }

 .quantity-btn {
   margin-left: 22px;
   font-size: 17px;
 }

 .form-info .quantity-btn {
   margin-right: 14px;
   font-size: 17px;
   direction: ltr;
 }

 button.qtyplus,
 button.qtyminus {
   background: #4c4c4c;
   border: none;
   border-radius: 100%;
   color: #fff;
   height: 29px;
   width: 29px;
 }

 .qtyinput {
   border: none;
   background: none;
   text-align: center;
   width: 45px;
   padding: 0px;
   font-size: 24px;
   font-weight: 500;
 }

 /* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 /* Firefox */
 input[type=number] {
   -moz-appearance: textfield;
 }

 .cart .sell-btn {
   /* background: linear-gradient(231deg, #e1234f, #ffabb0); */
   background: linear-gradient(231deg, #91db63, #4de57c);
   font-size: 17px;
 }

 .sell-btn {
   /* background: linear-gradient(231deg, #36C59E, #7EF2FF); */
   background: linear-gradient(231deg, #91db63, #4de57c);
   width: -moz-fit-content;
   width: fit-content;
   color: white;
   padding: 7px 23px;
   border-radius: 25px;
   font-size: 18px;
   margin-left: 24px;
   box-shadow: 4px 5px 18px #00000038;
   cursor: pointer;
   transition: all .9s;
   /* font-weight: 600; */
 }

 .sell-btn svg {
   transform: rotateY(1deg);
   transition: transform 0.8s;
 }

 .sell-btn:hover svg {
   transition: all .9s;
   transform: rotateY(191deg);
 }

 .sell-btn:hover {
   box-shadow: -4px 2px 20px #84d872;
   border-radius: 6px;
 }

 .call-btn {
   background: linear-gradient(231deg, #ffc0ba, #ef5f80);
   width: fit-content;
   color: white;
   padding: 7px 23px;
   border-radius: 25px;
   font-size: 20px;
   margin-left: 24px;
   box-shadow: 4px 5px 18px #00000038;
 }

 .call-btn svg,
 .sell-btn svg {
   margin-left: 14px;
 }

 .taksit-titel {
   display: flex;
   align-items: center;
 }

 .taksit-titel p {
   border: 1px solid;
   width: fit-content;
   padding: 1px 24px;
   border-radius: 33px;
   color: #A4C37D;
   font-size: 18px;
   font-weight: bold;
   margin-bottom: 0px;
   margin-right: 17px;
   line-height: 20px;
 }

 .product-taksit-list-price p {
   background: white;
   width: fit-content;
   border-radius: 20px;
   padding: 2px 22px;
 }

 .product-taksit-list-price p {
   background: #fff;
   background: linear-gradient(45deg, #fa7d98, #fda7a7);
   border-radius: 20px;
   box-shadow: -3px 1px 20px #0000001c;
   color: #fff;
   font-size: 15px;
   font-weight: 700;
   padding: 2px 22px;
 }

 .product-desc ul#pills-tab {
   background: white;
   width: fit-content;
   padding: 9px 34px;
   border-radius: 25px;
   box-shadow: -3px 3px 20px #00000024;
 }

 @media only screen and (max-width: 600px) {

   .product-desc ul#pills-tab {
     margin: auto;
     display: flex;
     justify-content: center;
   }
 }

 @media only screen and (max-width: 700px) {
   .product-page .sell-btn {
     position: fixed;
     bottom: 0;
     width: 100%;
     margin: 0 !important;
     z-index: +999;
     height: 56px;
     display: flex;
     left: 0;
     text-align: center;
     justify-content: center;
     align-items: center;
   }
 }

 .product-desc .product-desc-btn.active {
   background: black !important;
   border-radius: 28px !important;
   padding: 4px 20px;
   color: white !important;
 }

 .product-desc .product-desc-btn.active svg {
   fill: white;
 }

 .product-desc .product-desc-btn {
   color: black;
   padding: 4px 20px;
 }

 .product-desc .product-desc-btn svg {
   fill: black;
 }

 .product-desc .product-desc-btn:hover {
   color: #818286;
 }

 .product-desc div#pills-tabContent {
   background: white;
   border-radius: 28px;
   min-height: 301px;
   box-shadow: -3px 3px 20px #00000024;
   padding: 39px;
 }


 .product-taksit-section {
   /* border-bottom: 1px solid #AAC783; */
   padding-bottom: 25px;
 }

 .line {
   display: inline-block;
   width: 100%;
   height: 1px;
   background-color: #A4C37D;
   vertical-align: middle;
 }

 .product-page-price {
   align-items: flex-start;
   padding: 14px 19px;
   background: none;
 }

 .search-page .product-page-price {
   align-items: flex-start;
   padding: 7px 16px;
   background: none;
 }

 .product-page-price .tag-taksit {
   background: #F84771;
   padding: 0px 19px;
   line-height: 20px;
   border-radius: 21px;
   margin-bottom: 4px;
   color: #ffffff;
   font-weight: 700;
   font-size: 17px;
 }

 .product-page-price .price {
   color: #5d5e62;
   font-size: 17px;
   font-weight: 700;
 }

 .product-attr-item {
   display: flex;
   align-items: center;
   line-height: 16px;
 }

 .product-attr-item p {
   margin-bottom: 0;
   margin-left: 12px;
   font-weight: 700;
   font-size: 13px;
   line-height: 13px;
   font-style: italic;
   text-transform: uppercase;
 }

 .product-attr {
   display: flex;
   flex-wrap: wrap;
 }

 .price-section {
   display: flex;
   flex-wrap: wrap;
   /* flex-direction: column; */
 }

 .product-page .img-product {
   min-height: 140px;
   max-height: 140px;
   overflow: hidden;
 }

 .product-page .img-product img {
   object-fit: cover;
   width: 100%;
   height: 100%;
   min-height: 166px;
   border-radius: 6px;
 }

 span.product-mounth-time {
   font-size: 15px;
   font-weight: 400;
 }

 span.product-mounth {
   font-size: 10px;
   margin-right: 8px;
 }

 .image-gallery-content.fullscreen {
   background: white !important;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
 .image-gallery-thumbnail:focus {
   outline: none !important;
   border: 2px solid #FFA8BC !important;
   border-radius: 22px;
   box-shadow: 0px 0px 8px #ffa8bc;
   background: white;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
 .image-gallery-thumbnail:focus {
   outline: none !important;
   border: 2px solid #FFA8BC !important;
   border-radius: 17px;
   box-shadow: 0px 0px 8px #ffa8bc;
   background: white;
   width: 61px !important;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
 .image-gallery-thumbnail:focus {
   outline: none;
   border: 2px solid #FFA8BC;
   border-radius: 17px;
   box-shadow: 0px 0px 8px #ffa8bc;
   background: white;
   width: 61px !important;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-thumbnail:hover {
   outline: none !important;
   border: 2px solid #FFA8BC !important;
   border-radius: 17px;
   box-shadow: 0px 0px 8px #ffa8bc;
   background: white;
   width: 61px !important;
 }

 .image-gallery-content:not(.fullscreen) img.image-gallery-image {
   max-height: 453px !important;
   min-height: 453px !important;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-left-nav .image-gallery-svg,
 .image-gallery-right-nav .image-gallery-svg {
   height: 120px;
   width: 23px !important;
 }

 .fullscreen .image-gallery-fullscreen-button {
   top: -1px;
   margin-top: auto;
   z-index: +9;
   bottom: auto;
   background: #e35274;
   border-radius: 0px 0px 20px 20px;
   right: 10px;
 }

 .image-gallery-icon:hover {
   color: #ff5757;
 }

 .image-gallery-slide-wrapper.left,
 .image-gallery-slide-wrapper.right {
   display: inline-block;
   width: calc(100% - 74px) !important;
   border-radius: 19px;
   overflow: hidden;
   box-shadow: 3px 0px 16px #0000001a;
   border: 1px solid #8080802e;
 }

 .fullscreen .image-gallery-fullscreen-button svg {
   display: none;
 }

 .fullscreen .image-gallery-fullscreen-button::before {
   content: 'X';
   display: flex;
   width: 19px;
   height: 15px;
   justify-content: center;
   align-items: center;
   font-size: 25px;
 }

 .bg-product {
   background: white;
 }

 .product-btn-exist p {
   background: #484747;
   width: fit-content;
   padding: 6px 16px;
   border-radius: 19px;
   margin-bottom: 0;
   color: white;
 }

 .product-btn-exist span {
   font-size: 13px;
   color: #4a4647;
 }

 .image-gallery-content img.image-gallery-thumbnail-image {
   object-fit: cover;
   padding: 3px 0;
   width: 87%;
 }

 .image-gallery-thumbnail .image-gallery-thumbnail-inner {
   max-height: 54px;
   min-height: 56px;
   overflow: hidden;
 }

 .image-gallery-content:not(.fullscreen) .image-gallery-thumbnail:hover {
   background: #fff;
   border: 2px solid #ffa8bc !important;
   border-radius: 17px;
   box-shadow: 0 0 8px #ffa8bc;
   outline: none !important;
   width: 61px !important;
 }

 /* End Product Page */


 .alert-store {
  background: #f8d7da;
  padding: 5px 9px;
  margin-bottom: 8px;
  margin-top: 20px;
  border-radius: 8px;
  color: #282828;
  width: fit-content;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
 }

 .vendeur-old-version {
   color: #565656;
   background: antiquewhite;
   text-align: center;
   margin: auto;
   width: fit-content;
   display: flex;
   padding: 3px 12px;
   border-radius: 8px 0px;
   font-weight: 500;
 }



 .no-results {
   min-height: 284px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .cart-sum-loader {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 468px;
 }

 .text-danger {
   color: #627cdf !important;

 }

 /* START FOOTER  */
 section.footer {
   background: linear-gradient(179deg, #e53c57, #f59996);
   /* height: 301px; */
   margin-top: 298px !important;
 }

 section.footer p {
   color: white;
 }

 .logo-footer {
   margin-right: 34px;
   border-right: 1px solid white;
   padding-right: 20px;
 }

 .text-footer {
   display: flex;
   justify-content: center;
   /* align-items: center; */
   flex-direction: column;
 }

 .text-footer p {
   color: white;
   font-size: 13px;
   line-height: 14px;
 }

 .copyright {
   font-size: 18px;
   font-weight: 800;
   color: white;
 }

 .contact-footer p {
   margin-bottom: 7px;
   font-size: 15px;
 }

 @media only screen and (max-width: 992px) {
   .footer {
     text-align: center;
   }
 }

 @media only screen and (max-width: 1200px) {
   .copyright-logo {
     align-items: center !important;
     justify-content: center;
     text-align: center;
     flex-direction: column;
     margin-bottom: 25px;
   }

   .logo-footer {
     border-right: none;
     padding-right: 0px;
     margin-right: 0px;
   }
 }

 .footer-icon svg {
   margin-right: 11px;
 }

 /* END FOOTER */




 /*  */
 .screen {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #4e4949ad;
   display: flex;
   align-items: center;
   z-index: +99999999999999999999;
 }

 .loader {
   width: 100%;
   height: 15px;
   text-align: center;
 }

 .loader-wait {
   color: white;
   font-size: 21px;
   font-weight: 600;
   font-style: italic;
   text-transform: capitalize;
   margin-top: 23px;
 }

 .dot {
   position: relative;
   width: 15px;
   height: 15px;
   margin: 0 2px;
   display: inline-block;
 }

 .dot:first-child:before {
   animation-delay: 0ms;
 }

 .dot:first-child:after {
   animation-delay: 0ms;
 }

 .dot:last-child:before {
   animation-delay: 200ms;
 }

 .dot:last-child:after {
   animation-delay: 200ms;
 }

 .dot:before {
   content: "";
   position: absolute;
   left: 0;
   width: 15px;
   height: 15px;
   background-color: blue;
   animation-name: dotHover;
   animation-duration: 900ms;
   animation-timing-function: cubic-bezier(.82, 0, .26, 1);
   animation-iteration-count: infinite;
   animation-delay: 100ms;
   background: white;
   border-radius: 100%;
 }

 .dot:after {
   content: "";
   position: absolute;
   z-index: -1;
   background: black;
   box-shadow: 0px 0px 1px black;
   opacity: .20;
   width: 100%;
   height: 3px;
   left: 0;
   bottom: -2px;
   border-radius: 100%;
   animation-name: dotShadow;
   animation-duration: 900ms;
   animation-timing-function: cubic-bezier(.82, 0, .26, 1);
   animation-iteration-count: infinite;
   animation-delay: 100ms;
 }

 @keyframes dotShadow {
   0% {
     transform: scaleX(1);
   }

   50% {
     opacity: 0;
     transform: scaleX(.6);
   }

   100% {
     transform: scaleX(1);
   }
 }

 @keyframes dotHover {
   0% {
     top: 0px;
   }

   50% {
     top: -50px;
     transform: scale(1.1);
   }

   100% {
     top: 0;
   }
 }

 /*  */


 .forget-password {
   cursor: pointer;
 }

 .store-boutique-title-logo {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   position: relative;
 }

 @media only screen and (max-width: 992px) {

   .Aide-page .masthead {
     height: 100vh;
     min-height: 600px;
     padding-top: 63px;
     /* background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080'); */
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
   }

   .store-boutique-title-logo {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
     position: relative;
     flex-direction: column;
   }

   .boutique-single-logo {
     background: white;
     /* width: 260px;
  height: 260px; */
     border-radius: 123px;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     /* margin-top: -174px; */
     /* padding: 11px; */
     border: 6px solid #F5F7F9;
     /* position: absolute; */
     object-fit: cover;
     z-index: +999;
   }

   .boutique-single-logo {
     background: white;
     /* width: 260px;
  height: 260px; */
     border-radius: 123px;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: unset;
     /* padding: 11px; */
     border: 6px solid #F5F7F9;
     position: unset;
     object-fit: cover;
     z-index: +999;
   }

   .boutique-single-info {
     margin-top: 28px;
     margin-left: auto;
     margin-right: auto;
     color: #ffffff;
     position: unset;
     text-align: center;
   }

   .b-nav-section {
     margin-left: 40px;
     display: flex;
     height: 54px;
     justify-content: flex-start;
     /* align-items: center; */
   }
 }


 .dashboard-table input:checked+.slider {
   background: linear-gradient(45deg, #57c698, #0bedb9);
 }

 .dashboard-table .switch {
   position: relative;
   display: inline-block;
   width: 61px;
   height: 27px;
 }

 .dashboard-table .slider:before {
   position: absolute;
   content: "";
   height: 20px;
   width: 20px;
   left: 6px;
   bottom: 4px;
   background-color: white;
   transition: .4s;
 }

 .dashboard-table .slider.round .checked-p {
   text-align: initial;
   margin-top: 3px;
   margin-left: 6px;
   color: white;
   font-weight: 600;
 }

 .dashboard-table .slider.round .unchecked-p {
   text-align: right;
   margin-top: 3px;
   margin-right: 6px;
   color: white;
   font-weight: 600;
 }

 .dashboard-table input:checked+.slider:before {
   -webkit-transform: translateX(30px);
   -ms-transform: translateX(30px);
   transform: translateX(30px);
 }



 .cart-map-store {
   height: 250px;
   width: 100%;
   overflow: hidden;
   object-fit: cover;
   border-radius: 23px;
   box-shadow: 4px 4px 13px #c9c9c9;
   margin-top: 20px;
 }


 /*  */
 .react-loading-skeleton {
   z-index: 0 !important;
 }

 .slide-skeleton {
   min-height: 321px;
 }

 .slide-skeleton {
   min-height: 557px;
 }

 /*  */

 .note-form {
   font-size: 14px;
   color: #6e6767;
 }




 .swal2-styled.swal2-confirm {
   border: 0;
   border-radius: .25em;
   background: initial;
   background-color: #878787;
   color: #fff;
   font-size: 1em;
 }

 .swal2-styled.swal2-confirm:focus {
   box-shadow: 0 0 0 3px rgb(215 215 215 / 50%) !important;
 }

 .swal2-styled.swal2-confirm {
   border: 0;
   border-radius: .25em;
   background: initial;
   background-color: #1e94eb !important;
   color: #fff;
   font-size: 1em;
 }

 .boutique-alert-vide {
   text-align: center;
   font-weight: 600;
   color: #d5457a;
   margin: auto;
   border: 1px solid;
   padding: 7px 4px;
 }

 .account-store-btn svg {
  width: 26px;
}





/*  */

.lds-roller {
  display: flex;
  position: relative;
  width: 80px;
  height: 184px;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #474747;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  */



/*  */
/*  */
.popup-container {
  padding: 7px 49px;
}

.pre-simulation-popup {
  position: absolute;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  top: 0;
  /* left: 35%; */
  background: #e9e9e9b3;
  width: 100%;
  /* z-index: +99999; */
  min-height: 100vh;
  padding-bottom: 42px;
  padding-top: 100px;
}

.pre-simulation-popup .label-days {
  font-weight: 700;
  color: #83858b;
}

.err-cart-sum {
  text-align: center;
  font-size: 15px;
  color: red;
}

.cart-sum-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 468px;
}

.cart-simulation-titel {
  text-align: center;
  margin-top: 80px;
  font-weight: 600;
  font-size: 27px;
  /* border: 1px solid #005043; */
  /* background: linear-gradient(45deg, #E0234F, #FF517A); */
  background: linear-gradient(45deg, #484848, #444444);
  color: white;
  /* border-radius: 21px; */
  border-radius: 21px 21px 0 0;
  width: -moz-fit-content;
  width: 100%;
  margin: auto;
  padding: 20px 14px;
  font-weight: 900;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close-simulation {
  position: absolute;
  top: -12px;
  right: -16px;
  font-size: 18px;
  font-weight: 400;
  background: #ffffff;
  border-radius: 65px;
  padding: 8px 16px;
  cursor: pointer;
}

.btn-close-simulation div {
  color: #4c4b4b;
}

.cart-simulation-titel svg {
  /* width: 52px;
  height: 51px;
  margin-right: 22px; */
  fill: white;
}
.store-simulation-title {
  font-size: 25px;
  font-weight: 700;
  color: #83858b;
  /* text-transform: uppercase; */
  font-style: italic;
}
.product-taksit-list-price.mt-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ass-tel {
  align-items: center;
  display: flex;
  justify-content: center;
}
.simulation-amount {
  font-size: 15px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.cart-simulation-desc {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 52px;
  border: 2px solid #e1e1e1;
  padding: 7px 9px;
  background: #f3f3f3a6;
  font-size: 14px;
  color: #545454;
}

.cart-simulation-popup {
  max-width: 546px;
  width: 97%;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 20px;
  background: white;
  /* padding: 20px; */
  border-radius: 20px;
  box-shadow: -1px -1px 20px #b1b1b166;
  margin-top: 39px;
  /* overflow: hidden; */
}

@media only screen and (max-width: 560px) {
  .cart-simulation-popup {}
}

.remboursement-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.remb-box {
  background: #c7c7c7;
  height: 86px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  border-radius: 13px;
  cursor: pointer;
  flex-direction: column;
  margin-top: 8px;
}

.remb-box.active {
  background: linear-gradient(45deg, #E0234F, #FF517A);

}

.remb-box svg {
  /* margin-bottom: 18px; */
  width: 39px;
  height: 39px;
  fill: white;
  margin-bottom: 4px;
}

.rang-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  font-size: 18px;
}

.rang-popup-up {
  background: linear-gradient(45deg, #E0234F, #FF517A);
  padding: 3px 10px;
  border-radius: 19px;
  color: white;
  cursor: pointer;
  margin: 0 12px;
}

.btn-cart-simulation {
  border: none;
  padding: 6px 26px;
  background: linear-gradient(45deg, #6c6c6c, #686868);
  color: white;
  border-radius: 28px;
}

.max-amount {
  font-size: 27px;
  text-align: center;
  font-weight: 700;
  background: #ededed;
  color: #6f7172;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 11px 26px;
  border-radius: 18px;
  margin-top: 10px;
}

.cart-pre-sum {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.validation-section .titel {
  font-size: 19px;
  margin-bottom: 19px;
  font-weight: 400;
}

.user-info {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.user-info-items {
  margin-right: 20px;
}

p.user-info-items b {
  color: #7e8381;
}

.rang-popup span {
  font-size: 14px;
  font-weight: 400;
  margin-left: 7px;
}

.pre-simulation-popup .form-select {

  border: 1px solid #dbdbdb;
}

.pre-simulation-popup .slider {
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 25px;
  background: linear-gradient(45deg, #E0234F, #FF517A);
  /* background: linear-gradient(45deg, #F4C5C3, #fad1a3); */
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border-radius: 29px;
}
.pre-simulation-popup .slider:before {
  position: relative;
  content: "";
}

.pre-simulation-popup .slider:hover {
  opacity: 1;
}

.pre-simulation-popup .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: white;
  box-shadow: 0px 2px 17px #00000042;
  cursor: pointer;
  border-radius: 80px;
}

.pre-simulation-popup .slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: white;
  box-shadow: 0px 2px 17px #00000042;
  cursor: pointer;
  border-radius: 80px;

}

/*  */


.donwload-qr-code {
  margin: auto;
  text-align: center;
  margin-top: 34px;
  background: #495996;
  width: fit-content;
  padding: 10px 21px;
  cursor: pointer;
  color: white;
  font-weight: 700;
}

.currency-input {
  border: 1px solid #dbdbdb;
    padding: 12px 13px;
}
.input-symbol-euro {
  position: relative;
}
.input-symbol-euro input {
  padding-right: 18px;
}
.input-symbol-euro:after {
  position: absolute;
  top: 0;
  content: "DA";
  right: 10px;
  font-weight: 900;
}







.store-form-register--btn {
  background: linear-gradient(247deg, #ec325b, #ec325b);
  color: white;
  padding: 8px 34px;
  border-radius: 23px;
  box-shadow: 0px 8px 14px #e01c3b69;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: auto;
}
.store-form-register--btn:hover {
  background: linear-gradient(247deg, #cf6078, #e7899e);
  color: rgb(34, 34, 34);
}
.form-store-register .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ff3e69;
  outline: 0;
  box-shadow: 0 0 18px 0.25rem rgb(236 50 91 / 14%);
}
.form-store-register .err-cart-sum {
  text-align: center;
  font-size: 15px;
  color: #ec325b;
  margin-top: 4px;
  font-weight: 500;
}
.form-store-register label.form-label {
  font-weight: 600;
  color: #6b6b6b;
  font-size: 15px;
}

.form-store-register * :lang(ar) {
  font-family: 'El Messiri', sans-serif;
}





.lang-section {
  z-index: +9999999999;
}

i {
  font-family: var(--fa-style-family, "Font Awesome 6 Free") !important;
}

.lang-section .lang-menu {
  width: 29px;
  height: 28px;
  border-radius: 100%;
  object-fit: cover;
  /* box-shadow: 0px 1px 8px 4px #ffffffb8; */
  color: white;
  margin-right: 8px;
}

.lang-section .sl-nav li:hover .pad-lang {
  position: absolute;
  top: -32px;
  padding-right: 30px;
  padding-left: 65px;
  padding-top: 40px;
  left: 25px;
}

.lang-section .sl-nav {
  display: inline;
}

.lang-section .sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.lang-section .sl-nav li {
  cursor: pointer;
  /* padding-bottom: 10px; */
}

.lang-section .sl-nav li ul {
  display: none;
}

.lang-section .sl-nav li:hover ul {
  position: absolute;
  top: 29px;
  right: -15px;
  display: block;
  background: #fff;
  width: 120px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.lang-section .sl-nav li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}

.lang-section .sl-nav li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.lang-section .sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-right: 2px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}

.lang-section .sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.lang-section .sl-nav li ul li span {
  padding-left: 5px;
}

.lang-section .sl-nav li ul li span:hover {
  color: #61b4ee;
}

.lang-section .sl-nav li ul li span.active {
  color: #61b4ee;
  font-weight: 700;
}

.lang-section .sl-flag {
  display: inline-block;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4); */
  width: 18px;
  height: 18px;
  background: #e9244f;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.lang-section .flag-fr {
  /* background: url("https://icons.iconarchive.com/icons/wikipedia/flags/512/FR-France-Flag-icon.png"); */
  background-size: cover;
  background-position: center center;
}

.lang-section .flag-usa {
  background-size: cover;
  background-position: center center;
  /* background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII="); */
}

.lang-section .lang-text {
  font-size: 14px;
}


.dashboard-back {
  color: #ffffff;
  border: 1px solid #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 35px;
  cursor: pointer;
  background: linear-gradient(45deg, #f783ee, #797cfd) !important;
  border-radius: 8px;
}

.client-ar-info > * , .client-ar-info div ,.client-ar-info label ,.client-ar-info input ,.client-ar-info span, .client-ar-info select , .client-ar-info option{
  font-family: 'El Messiri', sans-serif;

}
.saidaty .alert-form {
  color: red;
  direction: rtl;
  font-size: 12px;
  margin-bottom: 5px;
}


.quill {
  height: 326px;
  margin-bottom: 80px;
}



.wafeer-form {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: white;
  padding: 78px 30px;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.section-sub {
  font-size: 21px;
  font-weight: 800;
  /* font-family: 'Poppins'; */
}
.wafeer-vertical-separation {
  height: 54px;
  width: 5px;
  background: linear-gradient(45deg, #FCA01F, #F27C00) !important;
  margin: auto;
}
.wafeer-login-btn-up {
  background: #4B4B4B;
  padding: 4px 27px;
  color: white;
  border-radius: 38px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  transition: all  .3s;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
}

.no {
  background: #ff000052;
  width: fit-content;
  padding: 4px 21px;
  text-align: center;
  margin: auto;
  border-radius: 20px;
  font-weight: 500;
  color: #7a2727;
}
.ok {
  background: #00ffa152;
  width: fit-content;
  padding: 4px 21px;
  text-align: center;
  margin: auto;
  border-radius: 20px;
  font-weight: 500;
  color: #105015;
}

.err-cart-sum {
  text-align: center;
  font-size: 16px;
  color: red;
  margin-top: 3px;
  font-weight: 500;
}
a.btn-dossier {
  color: #ffffff;
  border: 1px solid #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 11px;
  cursor: pointer;
  background: linear-gradient(45deg, #f783ee, #797cfd) !important;
  border-radius: 8px;
  margin: auto;
  display: flex;
}


.action-page main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}
/*** VARIABLES ***/
/* Colors */
/*** EXTEND ***/
/* box-shadow */
ol.gradient-list>li::before,
ol.gradient-list>li {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
.dar-idjar-page main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
  width: fit-content;
  margin: auto;
}

ol.gradient-list>li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  min-width: 700px;
  padding: 15px 49px 15px 15px;
  position: relative;
  font-size: 16px;
}
@media only screen and (max-width: 1100px) {
  
ol.gradient-list>li {
 
  min-width: auto;
  width: 100%;
}
}
ol.gradient-list>li::before,
ol.gradient-list>li::after {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  right: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

ol.gradient-list>li::before {
  align-items: flex-end;
  content: counter(gradient-counter);
  color: white;
  display: flex;
  font: 900 1.5em/1 "Montserrat";
  justify-content: flex-end;
  padding: 0.125em 0.25em;
  z-index: 1;
}

ol.gradient-list>li:nth-child(10n+1):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+2):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+3):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+4):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+5):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+6):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+7):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+8):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+9):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+10):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li+li {
  margin-top: 2rem;
}


.text-upload-btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 50%;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  color: #b2b4b5;
  font-size: 18px;
}
.delete-current-file {
  cursor: pointer;
  color: #ffffff !important;
  background: #f51874;
  text-align: center;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  padding: 3px 12px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 17px;
  margin-top: 3px;
}
.document-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}
.document-btn  .delete-btn {
  padding: 5px 10px;
}


.url-project-plus {
  background: linear-gradient(45deg, #2ac6d5, #4b86fe);
  color: white;
  width: fit-content;
  display: flex;
  padding: 5px 13px;
  border-radius: 18px;
  align-items: center;
}

.donwload-document-btn {
  background: linear-gradient(45deg, #2ac6d5, #4b86fe);
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  padding: 6px 27px;
  border-radius: 16px;
  align-items: center;
  cursor: pointer;
}


.remboursement-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.remb-box {
  background: #a1a1a1;
  height: 86px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  border-radius: 13px;
  cursor: pointer;
  flex-direction: column;
  margin-top: 8px;
}

.remb-box.active {
  background: linear-gradient(180deg, #4f806b, #5f9d84) !important;
}

.remb-box svg {
  /* margin-bottom: 18px; */
  width: 39px;
  height: 39px;
  fill: white;
  margin-bottom: 4px;
}

.saidaty .form-info input {
  direction: rtl;
}

.btn-doc {
  flex-wrap: wrap;
  gap: 17px;
}

.opacity-100 {
  opacity: 1 !important;
}

.iamal-gradiant {
  background: linear-gradient(45deg, #00a9ed, #01398e) !important;
}

.status-user {
  background: linear-gradient(247deg,#5da3a3,#999cdb);
  padding: 3px 3px;
  color: white;
  border-radius: 8px;
  width: fit-content;
  margin: auto;
  line-height: 1.2;
}

.empty-scan {
  background: #ededed;
  width: fit-content;
  margin: auto;
  padding: 0px 15px;
}

.lor-lang {
  direction: ltr !important;
  unicode-bidi: bidi-override;
}

.box-info p:nth-child(2) {
  font-size: 18px;
  line-height: 1;
}








.store-search-section {
  align-items: center;
  background: #fbfbfb;
  border-radius: 17px;
  box-shadow: 2px -1px 20px 4px #26262612;
  display: flex;
  margin: 40px auto auto;
  /* overflow-x: scroll; */
  padding: 31px 5px;
  width: 92%;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 9px
}
.store-search-section input {
  border: 3px solid #e3e3e3;
  border-radius: 22px;
  width: 237px;
}
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.store-search-section .form-select {
  border: 3px solid #e3e3e3;
  border-radius: 22px;
  width: 157px;
}
.store-search-section select {
  font-size: 13px;
  font-weight: 600;
}
.search-btn-dashboard {
  background: linear-gradient(45deg,#f783ee,#797cfd)!important;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px 23px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.data-state {
  /* margin-top: 54px; */
  display: flex;
  width: 100%;
  flex-direction: column;
}
.day, .month {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 8px;
}
.day-d {
  display: flex;
  font-size: 15px;
  color: #464646;
  margin-bottom: 9px;
}
.day-d span {
  width: 41px;
  height: 12px;
  display: flex;
  margin: auto;
  margin-right: 7px;
}
.color-r {
  background: #e5c1d5;
  border: 1px solid #fd6b8b;
}

.titel-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #9761f7, #89aaf7);
  /* margin-right: 0px; */
  color: white;
  padding: 7px;
  width: 220px;
  border-radius: 20px;
  margin-bottom: 24px;
  margin-top: 60px;
  margin: auto;
}

.color-b {
  background: #99adf3;
  border: 1px solid #9667f7;
}

.chart-iamal canvas {

  width: 100%;
  

}

.project .voir-btn {
  background: linear-gradient(45deg, #2ac6d5, #4b86fe);
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 8px;
  border-radius: 6px;
  font-size: 13px;
  margin: auto;
}

.links-equipment-img {
  width: 58px;
  min-height: 46px;
  height: 49px;
  overflow: hidden;
  border-radius: 19px;
  object-fit: cover;
}

.label-checkbox{
  scale: 2.3;
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap');

.lang-ar {
  font-family: 'El Messiri', sans-serif;
}

.equipment-item .product-price span {
  font-size: 12px;
  font-weight: 500;
}

.equipment-item .product-price {
  align-items: center;
  background: linear-gradient(45deg, #201f1f, #3e3e3e);
  /* border: 1px solid #feb5a5; */
  border-radius: 2px;
  color: #fff8f8;
  display: flex;
  font-size: 17px;
  font-weight: 700;
  justify-content: center;
  padding: 2px 18px;
  width: -moz-fit-content;
  width: fit-content;
  transform: skew(172deg, 357deg);
}

.tab-content>.active {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

 .cart-product-section {
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 18px;

}

.cart-product-name {
  font-size: 12px;
  margin-bottom: 2px;
}

.cart-product-marque {
  margin-top: 0px;
  font-weight: 700;
  font-size: 12px;
}

.product-item-img {
  max-height: 120px;
  overflow: hidden;
}

.product-item-img .cart-img {
  max-width: 81px;
  min-height: 81px;
  object-fit: cover;
}

.logo-svg-bea {
  width: 132px;
  margin: auto;
}